import React from 'react'
import { NotFound } from '@renderbus/common/components'

import Layout from '../molecules/layout'

class NotFoundPage extends React.PureComponent {
  render() {
    let {
      location,
      pageContext: {
        topThreeShare
      }
    } = this.props
    return (
      <Layout location={location} topThreeShare={topThreeShare}>
        <NotFound />
      </Layout>
    )
  }
}

export default NotFoundPage
